import { Colors, globalTheme } from 'srs.sharedcomponents/lib/esm/styles/config'

const vwThemeColor = '#004563';
export const customTheme = {
  colors: {
    ...globalTheme.colors,
    primary: vwThemeColor,
    menuButtonMobile: Colors.white,
    menuButton: Colors.white,
    cartButtonMobile: Colors.filterWhite,
    topNavigationMobile: Colors.white,
    continueShoppingButton: '#004563',
  },
}
